<template>
  <div>
      <v-card>
          <h2 class="pl-2">
              Aktualności
          </h2>
        <v-btn
            color="blue"
            class="float-right mr-10 white--text"
            @click.stop="addOrEdit()"
          >
          Dodaj aktualności
          <v-icon class="ml-2 white--text">
            mdi-alpha-c-circle
          </v-icon>
        </v-btn>
        <div style="clear:both"></div>
        <v-data-table
          :headers=headers
          :items=products
          :options.sync="options"
          :server-items-length="serverItemsLength"
        >
        <template slot="item.type" slot-scope="props">{{ props.item.type === 2 ? "Konkurs" : "Quiz" }}</template>
        <template
          slot="item.actions"
          slot-scope="props"
          class="justify-center"
          >
          <v-btn
            class="mx-2"
            icon
            @click="addOrEdit(props.item.id !== 0 ? JSON.parse(JSON.stringify(props.item)): null, props.item.type)"
          ><v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            icon
            @click="deleteItem(props.item)"
          ><v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>

        </v-data-table>
      </v-card>

      <confirm-delete-dialog
        endPoint="/article/DeleteArticle"
        ref="confirmDeleteDialog"
        @submit="getDataAsync"
      ></confirm-delete-dialog>
  </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'News',
  components: {
    ConfirmDeleteDialog
  },
  data () {
    return {
      headers: [
        { text: this.$t('contest.name'), value: 'name' },
        { text: this.$t('contest.description'), value: 'description' },
        { text: this.$t('contest.from'), value: 'from' },
        { text: this.$t('contest.to'), value: 'to' },
        { text: this.$t('product.actions'), value: 'actions', width: '10%', sortable: false }
      ],
      products: [],
      options: {},
      serverItemsLength: 0,
      endpoint: '/article/GetArticles'
    }
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint

      if (this.options.page) {
        url += '?'
        url += 'options.page=' + this.options.page + '&' +
        'options.itemsPerPage=' + this.options.itemsPerPage + '&' +
          '&options.sortBy=' + (this.options.sortBy !== null && this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'from') +
          '&options.sortDesc=' + (this.options.sortBy !== null && this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true) +
          '&type=5' + '&DateFilter=3'
      }

      const result = await this.$http.get(url)
      this.products = result.data.results

      this.serverItemsLength = result.data.totalCount
    },
    deleteItem (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = item.name
      this.$refs.confirmDeleteDialog.id = item.id
    },
    async addOrEdit (product) {
      const routerName = 'AddOrEditNews'
      if (product) {
        await this.$router.push({ name: routerName, params: { id: product.id, type: 5 } })
      } else {
        await this.$router.push({ name: routerName, params: { type: 5 } })
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
